<template>
<div class="container pt-3">
    <div class="p-5 mb-4 rounded-3" style="background-color: #1f5d96; color: whitesmoke;">
        <h1><span><img src="../../assets/Money_Graph300.png" height="50"></span> Flex Kredit</h1>
    </div>

    <div class="btn-group mb-2" role="group">
        <a class="btn btn-secondary" @click="notice()" role="button">
            Link zum Microsoft Store
        </a>
        <a class="btn btn-secondary " @click="notice()" role="button">
            Link zum Google Play Store
        </a>
        <a class="btn btn-secondary" href="https://flexkredit.eilers-systemsengineering.de/" target="_" role="button" data-bs-toggle="tooltip" data-bs-placement="top" title="In Entwicklung">
            Link zur HTML-Version
        </a>
    </div>

    <p>
        Mit Flex Kredit kann man unterschidliche Kredit-Szenarios betrachten unter Berücksichtigung von
        Sondertilgung und verschiedenenen Finanzierungslinien.
        <br>
        So können zusätzlich die unterschiedlichen Angebote der Banken verglichen werden und die Gesamtkosten können
        dargestellt werden.
        <br>
        Die Haushalst Ein- und Ausgaben können gegenübergestellt werden, um die Belastung aus dem Kredit zu überprüfen.
    </p>

    <div id="flexK" class="carousel carousel-dark slide" data-bs-ride="carousel">

        <div class="carousel-indicators">
            <button type="button" data-bs-target="#flexK" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#flexK" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#flexK" data-bs-slide-to="2" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#flexK" data-bs-slide-to="3" aria-label="Slide 4"></button>
            <button type="button" data-bs-target="#flexK" data-bs-slide-to="4" aria-label="Slide 5"></button>
        </div>
        <div class="carousel-inner text-center">
            <div class="carousel-item active">
                <img src="../../assets/Projekte/FlexKredit/Kredit_1.png" class="d-block w-100" alt="Kredit1">
            </div>
            <div class="carousel-item">
                <img src="../../assets/Projekte/FlexKredit/Kredit_2.png" class="d-block w-100" alt="Kredit2">
            </div>
            <div class="carousel-item">
                <img src="../../assets/Projekte/FlexKredit/Kredit_3.png" class="d-block w-100" alt="Kredit3">
            </div>
            <div class="carousel-item">
                <img src="../../assets/Projekte/FlexKredit/Kredit_4.png" class="d-block w-100" alt="Kredit4">
            </div>
            <div class="carousel-item">
                <img src="../../assets/Projekte/FlexKredit/Kredit_5.png" class="d-block w-100" alt="Kredit5">
            </div>            
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#flexK" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#flexK" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>    
</div>
</template>

<script>
export default {
  name: 'MTAdetails',
  methods: {
    notice() {
        this.$swal.fire(
            'Anwendung wird aktualsiert!',
            '',
            'warning'
        )
        return false;
    }
  }
}
</script>