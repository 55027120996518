<template>
<div class="container">
  <ul class="nav nav-tabs nav-fill">
    <li class="nav-item">
        <a class="nav-link active" data-bs-toggle="tab" href="#appo">Anwendungsentwicklung</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" data-bs-toggle="tab" href="#se">Systems Engineering</a>
    </li>
    <!-- <li class="nav-item">
        <a class="nav-link" data-bs-toggle="tab" href="#NWA">Nutzwertanalyse</a>
    </li> -->
    <li class="nav-item">
        <a class="nav-link" data-bs-toggle="tab" href="#PiS">Prozesse in Software</a>
    </li>        
    <!-- <li class="nav-item">
        <a class="nav-link" data-bs-toggle="tab" href="#login">Login</a> 
       
    </li> -->
  </ul>

    <!-- Tab panes -->
    <div class="tab-content" style="margin-top: 20px;">
        <div class="tab-pane container active" id="appo">
            <h4>Anwendungsentwicklung</h4>
            <p>
                Die Entwicklung von Software erfolgt nach den Maßgaben des Kunden, jedoch wird immer ein definierter
                Entwicklungsstandard eingehalten:
                <ul>
                    <li>End-to-End Tests</li>
                    <li>Versionierung in GIT</li>
                    <li>Dokumentation der Hauptfunktionalitäten</li>
                    <li>Agile Softwareentwicklung nach angepasster SCRUM-Methode</li>
                </ul>
            </p>

            <p>Die meisten Softwareprojekte weisen einen unterschiedlichen Grad an Komplexität auf,
                gerade dann wenn die Anwendungen auf einen Kunden maßgeschneidert sind und nicht von der Stange kommen.
                Für eine Aufwandsabschätzung müssen natürlich die Details bekannt sein. Aus diesem Grund sind die Preise
                in der Regel nur auf Anfrage.
            </p>

            <h4>Portfolio</h4>
            <div class="row row-cols-1 row-cols-md-3 g-4">
              <div class="col">
                <div class="card border-secondary h-100">
                    <div class="card-header">
                        <h5>einfache Webseite</h5>
                    </div>         
                    <div class="card-body">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">Web Design</li>
                            <li class="list-group-item">SSL-Sicherheit</li>
                            <li class="list-group-item">Preis: 450 €</li>
                            <li class="list-group-item text-muted ">optional: Hosten (25 € pro Monat)</li>
                        </ul>
                    </div>
                    <div class="card-footer">            
                        <div class="d-flex justify-content-center">
                            <router-link to="/contact" class="btn btn-primary"><i class="bi bi-envelope-fill"></i> KONTAKT</router-link>
                        </div>            
                    </div>
                </div>
              </div>

              <div class="col">
                <div class="card border-secondary h-100">
                    <div class="card-header">
                        <h5>komplexe Webseite</h5>
                    </div>
                    <div class="card-body">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">Web Design</li>
                            <li class="list-group-item">SSL-Sicherheit</li>
                            <li class="list-group-item">Anbindung API oder Datenbank</li>
                            <li class="list-group-item">Preis: Auf Anfrage</li>
                            <li class="list-group-item text-muted ">optional: Hosten (25 € pro Monat)</li>
                        </ul>
                    </div>
                    <div class="card-footer">            
                        <div class="d-flex justify-content-center">
                             <router-link to="/contact" class="btn btn-primary"><i class="bi bi-envelope-fill"></i> KONTAKT</router-link>              
                        </div>            
                    </div>
                </div>
              </div>

              <div class="col">
                <div class="card border-secondary h-100">
                    <div class="card-header">
                        <h5>Web-App</h5>
                    </div>
                    <div class="card-body">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">Web Design</li>
                            <li class="list-group-item">Front-End</li>
                            <li class="list-group-item">Back-End</li>
                            <li class="list-group-item">SSL-Sicherheit</li>
                            <li class="list-group-item">Anbindung API oder Datenbank</li>
                            <li class="list-group-item">Benutzerverwaltung</li>
                            <li class="list-group-item">Preis: Auf Anfrage</li>
                            <li class="list-group-item text-muted ">optional: Hosten (25 € pro Monat)</li>
                        </ul>
                    </div>
                    <div class="card-footer">            
                        <div class="d-flex justify-content-center">
                             <router-link to="/contact" class="btn btn-primary"><i class="bi bi-envelope-fill"></i> KONTAKT</router-link>
                        </div>            
                    </div>
                </div>
              </div>

              <div class="col">
                <div class="card border-secondary h-100">
                    <div class="card-header">
                        <h5>Konsolen-App</h5>
                    </div>         
                    <div class="card-body">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">Algorithmen Entwicklung</li>
                            <li class="list-group-item">Anbindung API oder Datenbank</li>
                            <li class="list-group-item">Preis: Auf Anfrage</li>
                        </ul>
                    </div>
                    <div class="card-footer">            
                        <div class="d-flex justify-content-center">
                             <router-link to="/contact" class="btn btn-primary"><i class="bi bi-envelope-fill"></i> KONTAKT</router-link>
                        </div>            
                    </div>
                </div>
              </div>

              <div class="col">
                <div class="card border-secondary h-100">
                    <div class="card-header">
                        <h5>UWP Windows10 App</h5>
                    </div>
                    <div class="card-body">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">GUI Design</li>
                            <li class="list-group-item">Algorithmen Entwicklung</li>
                            <li class="list-group-item">Anbindung API oder Datenbank</li>
                            <li class="list-group-item">Preis: Auf Anfrage</li>
                        </ul>
                    </div>
                    <div class="card-footer">            
                        <div class="d-flex justify-content-center">
                             <router-link to="/contact" class="btn btn-primary"><i class="bi bi-envelope-fill"></i> KONTAKT</router-link>
                        </div>            
                    </div>
                </div>
              </div>

              <div class="col">
                <div class="card border-secondary h-100">
                    <div class="card-header">
                        <h5>Android App</h5>
                    </div>         
                    <div class="card-body">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">Design</li>
                            <li class="list-group-item">SSL-Sicherheit</li>
                            <li class="list-group-item">Preis: Auf Anfrage</li>
                            <li class="list-group-item text-muted ">optional: Bereitstellung im Play-Store</li>
                        </ul>
                    </div>
                    <div class="card-footer">            
                        <div class="d-flex justify-content-center">
                             <router-link to="/contact" class="btn btn-primary"><i class="bi bi-envelope-fill"></i> KONTAKT</router-link>
                        </div>            
                    </div>
                </div>
              </div>
            </div>
        </div>
        <div class="tab-pane container fade" id="se">
            <h4>Systems Engineering</h4>
            <p>Was ist die Systemtechnik oder in englisch "Systems Engineering"?</p>
            <p>
                Systems Engineering ist das Bindeglied zwischen unterschiedlichen Bereichen einer Projektrealisierung,
                um komplexe Systeme zu beherrschen.
                Wichtig hierbei ist das proaktive Aufgreifen und Managen von System-Schnittstellen
                sowie die ständige Kommunikation mit allen Projektbeteiligten.
            </p>

            <div class="alert alert-info clearfix">
                <strong>Das Ganze ( System ) ist mehr als die Summer seiner Teile ( Subsysteme ).</strong> <br>
                <span class="float-right"> <i>Aristoteles</i></span>
            </div>

            <p>In der folgenden Abbildung 1. sind die Funktionen des Systems Engineering und Schnittstellen zu anderen
                Teilbereichen eines Projektes dargestellt.</p>
            <img src="../assets/SE_function1_de.png" class="img-fluid" alt="Bild">
            <figcaption>Abbildung 1: System Engineering Funktionen und Schnittstellen zu anderen Disziplinen <i>Quelle:
                    nach ECSS-E-ST-10C</i></figcaption>

            <p style="margin-top: 20px;">In der Abbildung 2. sind die Funktionen und Beziehungen innerhalb des Systems
                Engineering dargestellt.</p>
            <img src="../assets/SE_function2_de.png" class="img-fluid" alt="Bild">
            <figcaption>Abbildung 2: System Engineering Funktionen und Beziehungen <i>Quelle: nach ECSS-E-ST-10C</i>
            </figcaption>

        </div>
        <!-- <div class="tab-pane container fade" id="NWA">
            <p>
                Die Nutzwertanalyse ist ein Werkzeug zur Entscheidungsfindung im System Engineering Prozess.

            </p>
            <img src="../assets/Einordnung der Nutzwertanaylse.jpg" class="img-fluid" alt="Bild">
        </div> -->
        <div class="tab-pane container fade" id="PiS">
            <h4>Prozess zur Erstellung einer Softwarelösung </h4>
            <p>
                Die betriebsinternen Prozesse werden analysiert und die Schnittstellen werden
                harmonisiert damit alle neu erstellten Softwareelemente ihre Daten zentral abbilden können.
            </p>
            <p>
                Die Abbildung 1 zeigt die Zusammenfassung unterschiedlicher Geschäftsprozesse, welche hier
                beispielsweise auf einem lokalen (on-premises) IT-System laufen.
                Durch die neu erschaffene digitale Verbindung der Geschäftsprozesse kann ein Mehrwert erzeugt werden
                indem Geschäftskennzahlen analysiert und visualisiert werden können.
                <ul>
                    <li>
                        So kann beispielsweise der Monatliche Verbrauch eines Produktionsgutes ausgewertet werden. In
                        Verbindung mit Logistikprozessen kann diese Ware dann zeitnah nachbestellt werden.
                        Außerdem wird aufgezeigt welches Produktionsgut häufiger genutzt wird.
                    </li>
                    <li>
                        Marketing Maßnahmen können auf einfache Art und Weise mit den Geschäftsprozessen verknüpft
                        werden.
                    </li>
                </ul>

            </p>
            <img src="../assets/Fotowarenkorb_kunde.png" class="img-fluid" alt="Bild">
            <figcaption>Abbildung 1: Exemplarische Darstellung einer Aggregation von unterschiedlichen
                Geschäftsprozessen </figcaption>

            <br>

            <strong>Der Prozess unterteilt sich in folgende mögliche Schritte:</strong>
            <ul>
                <li>Aufnahme bestehender Abläufe</li>
                <li>Identifizierung von Optimierungsmaßnahmen</li>
                <li>Abstimmen von gewünschten Optimierungsmaßnahmen</li>
                <li>Evaluierung bestehender IT-Hardware- und Softwareausstattung</li>
                <li>Erarbeitung eines Software-Realisierungsplans</li>
                <li>Abstimmung der Planung</li>
                <li>Erstellen und Präsentation eines Prototypens</li>
                <li>Erstellen der produktiven Software</li>
                <li>Einführen der produktiven Software in Teilbereichen</li>
            </ul>
        </div>
        <div class="tab-pane container fade" id="login">
            <h4>Login</h4>
            <p>
                Der Loginbereich führt Sie zu Ihrer Projektübersicht und informiert Sie über den aktuellen Stand unseres
                gemeinsames Projektes.
            </p>
            <a href="ProjektApps/login.html" target="_"> Zur Anmeldung</a>
            <div id="loginarea"></div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'Service',

}
</script>

<style scoped>
.nav-tabs {
  border-bottom: 1px solid #1f5d96;
}

.nav-link {
  color: black;
}

.nav-tabs .nav-link:hover {
  border-color: #1f5d96 #1f5d96 #dee2e6;
}

.nav-tabs .nav-link.active {
  color: whitesmoke;
  background-color: dimgray;
  /*  #1f5c96a2; */
}
</style>