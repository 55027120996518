<template>
<div class="container pt-3">
    <div class="p-5 mb-4 rounded-3" style="background-color: #1f5d96; color: whitesmoke;">
        <h1>Orbital Decay</h1>
    </div>
    <a class="btn btn-primary float-end" role="button"
        href="https://satdecay.eilers-systemsengineering.de/" target="_">
        Link zur Anwendung
    </a>

    <h1>Satelite Decay</h1>

    <article>
        Dieses kleine Programm berechnet die Zeit eines Satelliten im niedrigen Erdorbit bis zum wiedereintritt. Dabei werden rudimentäre atmosphärische Bahnstörungen berücksichtigt 
        sowie die physikalischen Parameter des Satelliten. Mit dem verwendeten Modell können Wiedereintrittszeiten eines Satelliten bis zu einer Bahnhöhe von 500 km berechnet werden.

        

    </article>
    <br>
    <p>Bei Fragen können sie mich gerne per Kontaktformular ansprechen.</p>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>