<template>
<div class="container pt-3">
    <div class="p-5 mb-4 rounded-3" style="background-color: #1f5d96; color: whitesmoke;">
        <h1>MTA</h1>
    </div>
    
    <p>
        Mit der MTA kann auf einfache Weise eine Grafik erzeugt werden, um eventuelle Probleme im Projekt zu visualisieren
        und in den Berichten auf diesen Umstand hinzuweisen.
    </p>
    <p>
        Man muss lediglich die Zeitdauer eines Projektes angeben und die Berichtszeiträume. Daraus wird eine Tabelle erzeugt. 
        In diese Tabelle kann man zu jedem Berichtszeitraum die Daten der Meilensteine eintragen. Aus diesen Daten wird dann ein Graph erstellt.
    </p>
    <a class="btn btn-primary" role="button"
        href="ProjektApps/mta/index.html" target="_">
        Link zur MTA
    </a>
    <div class="text-center">
        <img src="../../assets/MTA_Chart.png" height="500">
    </div>
</div>
</template>


<script>
export default {
  name: 'MTAdetails',
}
</script>