<template>
  <div class="home container">
        <img alt="Vue logo" src="../assets/fav1.png" />
        <h1>Herzlich Willkommen!</h1>
        
        <h3>"Die erste Stufe zum Erfolg ist der Wille zur Veränderung."</h3>
        <br/>
        <div style="text-align: justify;" class="justify-content-center">
          <ul>
            <li>
              <h5>Sie möchten Ihre Zeit für Ihre Kunden verwenden und nicht mühsam ihre Papiere sortieren?</h5>
            </li>
            <li>
              <h5>Sie möchten am Abend die Zeit mir Ihrer Familie verbringen oder den Abend gemütlich ausklingen lassen, statt viel Zeit aufzuwenden Angebote zu schreiben?</h5>
            </li>
            <li>
              <h5>Dann suchen Sie für Ihr Unternehmen eine Softwarelösung zur Unterstützung ihrer betriebsinternen Prozesse!</h5>
            </li>
          </ul>
          
          <h4>Durch einen engen Dialog mit Ihnen entsteht eine Softwarelösung, maßgeschneidert auf die Prozesse in Ihrem Unternehmen.</h4>
        </div>


        <div style=" margin-top: 75px; margin-bottom: 50px;">
            <h3>Ich freue mich sehr mit Ihnen zusammenzuarbeiten!</h3>
            <router-link to="/contact" class="btn btn-primary"><i class="bi bi-envelope-fill"></i> KONTAKT</router-link>
        </div>
  </div>
</template>

<script>


export default {
  name: 'Home',
  props: {
    msg: String
  },
  components: {
        
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home{
    text-align: center;
}
/* h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
*/
</style>
