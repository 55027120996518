<template>
<div class="container contact">
    <h4>Nehmen Sie Kontakt auf :</h4>

    <div class="row ">
        <div class="col-4 contaktfield rounded-start">
            <a href="mailto:info@eilers-systemsengineering.de">
                <i class="bi bi-envelope-fill"></i> <br>Info@E-SE.de
            </a>
        </div>
        <div class="col-4 contaktfield spezial">
            <i class="bi bi-geo-alt-fill"></i>
            <p>Falkensee, DE</p>
        </div>
        <div class="col-4 contaktfield rounded-end">
            <a href="tel:+4915156613405">
                <i class="bi bi-telephone-fill"></i><br>0151 / 56613405
            </a>
        </div>
    </div>

    <hr>
    <form id="email_form" @submit="postMail">
        <div class="form-group mb-3">
            <label for="Name1">Name:</label>
            <input type="text" class="form-control" v-model="name" name="name1" placeholder="Name" />
        </div>
        
        <div class="form-group mb-3">
            <label for="Email1">E-Mail:</label>
            <input type="email" class="form-control" v-model="email"  name="email1" placeholder="E-Mail" required/>
            <small id="emailHelp" class="form-text text-muted">E-Mailadressen werden nicht an dritte mitgeteilt.</small>
        </div>
        <div class="form-group mb-3">
            <label for="Name1">Nachricht:</label>
            <textarea rows="3" type="text" v-model="message" class="form-control" name="text1" required></textarea>
        </div>
        <button type="submit" class="btn btn-primary"><i class="bi bi-send-fill"></i> Nachricht absenden</button>
    </form>
</div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Contact',
    data: () => ({
        name: '',
        email:'',
        message: ''
    }),
    methods: {
        postMail(e) {
            e.preventDefault()

            axios.post(
                '../scripts/email.php',
                {
                    name1: this.name,
                    email1: this.email,
                    text1: this.message
                },
                )
                .then(response => {
                        console.log('success', response);
                        this.$swal.fire('E-Mail verschickt!', '', 'success')
                })
                .catch(error => {
                        console.log(error.response);
                        this.$swal.fire({
                            titel: 'Fehler!',
                            text: 'Bitte Angaben überprüfen!',
                            icon: 'error',
                            confirmButtonColor: '#3085d6'})
                });
        }
    }
}
</script>

<style scoped>
.contact {
    padding-top: 20px;
}
.contaktfield {
    color: whitesmoke;
    background-color: dimgray;
    text-align: center;
    border-radius: 0px;
    height: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: table-cell;
    vertical-align: middle;
    padding-top: 20px;
}

.spezial {
    background-color: #1f5d96;
}

.contaktfield i {
    font-size: xx-large;
}

a {
    color: whitesmoke;
}

a:hover {
    text-decoration: none;
    color: #1f5d96;
}

</style>>