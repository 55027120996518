<template>
    <div class="container">
        <!-- <nav class="navbar sticky-top navbar-expand-lg navbar-light bg-light" id="projekteNAV">
            <div class="container-fluid">
                <a class="navbar-brand" href="#">Projekte</a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul class="navbar-nav">
                        <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#item-1" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Projektmanagement
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <li><a class="dropdown-item" href="#item-1-1">Slim-MTA</a></li>                            
                            <li><a class="dropdown-item" href="#">Slim-Projektmanagement</a></li>
                            <li><a class="dropdown-item" href="#">Slim-Teamkalender</a></li>
                            <li><a class="dropdown-item" href="#">Slim-Risikobehandlung</a></li>
                            <li><a class="dropdown-item" href="#">Slim-Personalplanung</a></li>
                        </ul>
                        </li>
                        <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#item-2" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Weltraum
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <li><a class="dropdown-item" href="#item-2-1">Satelliten-Lebenszeit</a></li>
                            <li><a class="dropdown-item" href="#">Raketenstarts</a></li>
                            <li><a class="dropdown-item" href="#">Debris</a></li>
                        </ul>
                        </li>
                        <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#item-3" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Unterschiedliches
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <li><a class="dropdown-item" href="#item-3-1">Calculon</a></li>
                            <li><a class="dropdown-item" href="#item-3-2">Flex-Kredit</a></li>
                            <li><a class="dropdown-item" href="#">Another action</a></li>
                            <li><a class="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav> -->

        <div data-bs-spy="scroll" data-bs-target="#projekteNAV" data-bs-offset="0" tabindex="0">
            <h4 id="item-1">Projektmanagement</h4>
            <p>Im Bereich des Projektmanagements werden unterschiedliche Werkzeuge vorgestellt, die dabei helfen ein Projekt durchzuführen.</p>            
            
            <div class="card mb-3 bg-light" style="max-width: 1080px;">
                <div class="row g-0">
                    <div class="col-md-4">
                        <img src="../assets/MTA_Chart.png" class="img-fluid rounded-start" alt="MTA">
                    </div>
                    <div class="col-md-8">
                        <div class="card-body">
                            <h5 class="card-title" id="item-1-1">Slim-MTA</h5>
                            <p class="card-text">Die Meilenstein-Trend-Analyse ist ein Werkzeug im Projektmanagement. Es dient als Indikator und Visualisierung für zeitliche Probleme in Projekten. Die MTA ist eine Toolbox aus dem Slim-PM und kann auch separat genutzt werden.</p>
                            
                        <div class="mt-3"  style="position: absolute; bottom: 10px; right: 10px;">
                            <router-link to="/mta"  class="btn btn-outline-primary">Details</router-link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <h4 id="item-2">Weltraum</h4>
            <p>In diesem Bereich werden Informationen und Werkzeuge für Berechungen von Satellitenmissionen bereitgestellt. </p>
            <div class="card mb-3 bg-light" style="max-width: 1080px;">
                <div class="row g-0">
                    <div class="col-md-4">
                        <img src="../assets/decay.png" class="img-fluid rounded-start" alt="MTA">
                    </div>
                    <div class="col-md-8">
                        <div class="card-body">
                            <h5 class="card-title" id="item-2-1">Satelliten-Lebenszeit</h5>
                            <p class="card-text">Mit der Berechnung der Satelliten-Lebenszeit wird mit Angabe von Satelliten- und Umweltparametern ein Aproximierte Verweildauer im Orbit berechnet.</p>
                            
                        <div class="mt-3"  style="position: absolute; bottom: 10px; right: 10px;">
                            <router-link to="/decay"  class="btn btn-outline-primary ">Details</router-link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <h4 id="item-3">Unterschiedliches</h4>
            <p>Hier sind unterschiedliche Applikationen dargestellt</p>
            
            <div class="card mb-3 bg-light" style="max-width: 1080px;">
                <div class="row g-0">
                    <div class="col-md-4">
                        <img src="../assets/Funktionsgrafik.png" class="img-fluid rounded-start" alt="MTA">
                    </div>
                    <div class="col-md-8">
                        <div class="card-body">
                            <h5 class="card-title" id="item-3-1">Calculon</h5>
                            <p class="card-text">Ein kleines Taschenrechnerspiel auf Grundschulniveau, das für gelöste Aufgaben die Punkte zählt und unterschiedliche Operationen bereithält. Calculon ist eine Android-App.</p>
                            
                        <div class="mt-3"  style="position: absolute; bottom: 10px; right: 10px;">
                            <router-link to="/calculon"  class="btn btn-outline-primary ">Details</router-link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card mb-3 bg-light" style="max-width: 1080px;">
                <div class="row g-0">
                    <div class="col-md-4">
                        <img src="../assets/Money_Graph300.png" class="img-fluid rounded-start" alt="MTA">
                    </div>
                    <div class="col-md-8">
                        <div class="card-body">
                            <h5 class="card-title" id="item-3-2">Flex-Kredit</h5>
                            <p class="card-text">Tool um unterschiedliche Kreditlinien zu vergleichen und Sondertilgungen zu berücksichtigen. Speziell soll dieses Werkzeug bei der Hausfinanzierung helfen. Hierzu können auch unterschiedliche Zins-Szenarios evaluiert werden. </p>
                            
                        <div class="mt-3"  style="position: absolute; bottom: 10px; right: 10px;">
                            <router-link to="/flex"  class="btn btn-outline-primary ">Details</router-link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'Projects',

}
</script>

<style>

</style>