<template>
  <div class="container-xxxl">
    <NavBar/>

    <div class="content" id="mycontent">
      <div id="ese_header" class="sticky-top clearfix collapse">
        <img src="./assets/fav1.png" class="float-end" height="150" alt="Eilers-Systemsengineering">

        <span id="hamburger" @click="ese_open()"><i class="bi bi-list"></i></span>
      </div>

      <router-view/>
    </div>

    <footer class="fixed-bottom">
      <p>Copyright © 2022 Eilers-Systemsengineering. Alle Rechte vorbehalten.</p>
    </footer>
  </div>

  <div class="modal fade" id="impressum" tabindex="-1" aria-labelledby="impressumLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg modal-fullscreen-sm-down">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="impressumLabel">Impressum</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <Impressum/>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">schließen</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="datenschutz" tabindex="-1" aria-labelledby="datenschutzLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg modal-fullscreen-sm-down">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="datenschutzLabel">Datenschutz</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <Datenschutz/>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">schließen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Impressum from './components/impressum.vue'
import Datenschutz from './components/datenschutz.vue'
import NavBar from './components/NavBar.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    Impressum,
    Datenschutz    
  },
  methods: {
    ese_open() {
        let myNav = document.getElementById("myNav");
        let content = document.getElementById("mycontent");
        let header = document.getElementById('ese_header');

        header.classList.remove("show");
        myNav.classList.add("show");
        content.style.marginLeft = '300px';
        
       /*  if (this.width > 1024) {           
            this.isVis = true
            
        }
        else{
            this.isVis = false
            header.classList.add("show")
            content.style.marginLeft = '0px';
        } */
      
    }
  }
  
}
</script>

<style>
 html, body {
  margin: 0;
  padding: 0;
  height: 100%;

  min-height: 100%;
}

a {
  text-decoration: none;
}

#app {
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  margin: 0;
  color: #2c3e50;
  
  background-color:   #f1f1f1;
}

.content {
  position: relative;
  margin-left: 300px;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
  max-width: 100%;
  padding-bottom: 30px;
}

footer {    
    min-height: 2em;
    width: 100%;
    text-align: center;
    overflow: hidden;
    background-color: rgb(31, 93, 150);
    color: whitesmoke;
    z-index: 2002;
}

a {
  color: #1f5d96;
}

.btn-primary {
   background-color: #1f5d96 !important;
}

.btn-primary:hover {
   background-color: #1f5c96a2 !important;
}

.btn-outline-primary:hover {
    background-color: #1f5d96 !important;
}

#ese_header{
    background-color: #f1f1f1;
}

#hamburger {
    padding: 15px;
    font-size: xx-large;
    color: black;
}

#hamburger:hover {
    background-color:  #1f5c96a2;
}
</style>
