import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Contact from '../views/Contact'
import Service from '../views/Service'
import Projects from '../views/Projects'
import MTA from '../views/Projekte/MTA_details'
import Flex from '../views/Projekte/flexKredit'
import Calculon from '../views/Projekte/calculon'
import Decay from '../views/Projekte/decay'

const routes = [
  {
    path: '/',
    alias: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/service',
    name: 'Service',
    component: Service
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects    
  },
  {
    path: '/mta',
    name: 'MTA',
    component: MTA
  },
  {
    path: '/flex',
    name: 'Flex',
    component: Flex
  },
  {
    path: '/calculon',
    name: 'Calculon',
    component: Calculon
  },
  {
    path: '/decay',
    name: 'Decay',
    component: Decay
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
