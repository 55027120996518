<template>
<Transition>
    <div class="sidenav collapse" id="myNav">
        
        <img alt="Eilers Systems Engineering" src="../assets/ESE_logo2.png" width="250"/>
        <div id="nav">
            <router-link @click="toggleSideNav()" to="/"><i class="bi bi-house-fill"></i>WILLKOMMEN</router-link>
            <router-link @click="toggleSideNav()" to="/projects"><i class="bi bi-collection-fill"></i>PROJEKTE</router-link>
            <div v-if="isProject" class="ms-3">
                <ul>
                    <li v-for="project in projects" :key="project.id">
                        <router-link :to="project.url" @click="toggleSideNav()">
                            {{ project.name }}
                        </router-link>
                    </li>
                </ul>
    
            </div>
            <router-link @click="toggleSideNav()" to="/service"><i class="bi bi-megaphone-fill"></i>SERVICE</router-link>            
            <router-link @click="toggleSideNav()" to="/about"><i class="bi bi-balloon-heart-fill"></i>ÜBER</router-link>
            <router-link @click="toggleSideNav()" to="/contact"><i class="bi bi-envelope-fill"></i>KONTAKT</router-link>

            <!-- <router-link to="/ProjektApps/login.html" target="_" class="mt-3"><i class="bi bi-person-fill"></i>LOGIN</router-link> -->
            <a href="ProjektApps/login.html" class="mt-3" target="_"><i class="bi bi-person-fill"></i> LOGIN </a>
        
        </div>
        
        <div style="position: absolute; bottom: 4em;">
            <div id="impressum_btn">
                <button class="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#impressum">Impressum</button>
            <button class="btn btn-outline-secondary" style="margin-left: 10px;" data-bs-toggle="modal" data-bs-target="#datenschutz">Datenschutz</button>               
        </div>
        </div>
    </div>
</Transition>
</template>

<script>
export default {
    name: 'NavBar',
    data: () => ({
        isVis: true,
        projects: [
            {
                name: 'Slim-MTA',
                url: '/mta',
                rName: 'MTA'       
            },
            {
                name: 'Satelliten-Lebenszeit',
                url: '/decay',
                rName: 'Decay'          
            },
            {
                name: 'Calculon',
                url: '/calculon',
                rName: 'Calculon'  
            },
            {
                name: 'Kreditberechnung',
                url: '/flex',
                rName: 'Flex'        
            }
        ],
        width: document.documentElement.clientWidth
    }),

    mounted() {
        window.addEventListener('resize', this.toggleSideNav);
        this.toggleSideNav();
    },
    unmounted() {
        window.removeEventListener('resize', this.toggleSideNav);
    },

    computed: {
        isProject () {
            //var test = false
            for (const project of this.projects){                
                if (this.$route.name  === project.rName) 
                    return true
            }
            if (this.$route.name === 'Projects') 
                return true
            else
                return false
        }
    },

    methods: {
    toggleSideNav() {

        this.width = document.documentElement.clientWidth;
        let content = document.getElementById("mycontent");
        let header = document.getElementById('ese_header');
        let myNav = document.getElementById('myNav');

        if (this.width > 1024) {           
            myNav.classList.add("show")
            content.style.marginLeft = '300px';
            header.classList.remove("show")
        }
        else{
            myNav.classList.remove("show")
            header.classList.add("show")
            content.style.marginLeft = '0px';
        }
    }
  }        
}
</script>

<style>
#nav {
    display: grid;
    margin-top: 20px;
}

#nav a {
    /* color: snow; */
    color: #2c3e50;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    text-decoration: none;
    cursor: pointer;
}
#nav a i {
    margin-right: 15px;
    min-width: 20px;
    text-align: center;
}

#nav a:hover {
    text-decoration: none;
    color: whitesmoke;
    background-color:  #1f5c96a2;
}

.sidenav {    
    width: 300px;
    height: 100%;
    background-color: white;
    overflow-x: hidden;
    padding-top: 20px;
    padding-left: 10px;
    position: fixed;
}

#impressum_btn{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

#closeOverlay {
    width: 50px;
    height: 50px;
    font-size: xx-large;
    color: black;
    margin-right: 0px;
    text-align: center;
    z-index: 2;
    top: 0;
    left: 250px;
    position: absolute;
    display: none;
}

#closeOverlay:hover {
    background-color:  #1f5c96a2;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>