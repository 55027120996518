<template>
<div class="container pt-3">
    

    <div class="p-5 mb-4 rounded-3" style="background-color: #1f5d96; color: whitesmoke;">
        <h1><span><img src="../../assets/Projekte/calculon/Präsentation1.png" height="50"></span> Calculon</h1>
    </div>
    <div class="btn-group mb-2 float-right" role="group">
        <a class="btn btn-primary" role="button"
            href="https://play.google.com/store/apps/details?id=eilerssystemsengineering.calculon&hl=de" target="_">
            Link zum Google Play Store
        </a>
        <a class="btn btn-primary" role="button"
            href="https://littleprof.eilers-systemsengineering.de/" target="_">
            Link zur HTML-Version
        </a>
    </div>

    
    <p>
        Kleines Spiel, um damit Kopfrechnen in den Grundrechenarten zu lernen und üben.
    </p>
    <strong>
        Hauptfunktionalitäten:
    </strong>
    <ul>
        <li>Es können unterschiedliche Schwierigkeitsstufen eingestellt werden.</li>
        <li>Es kann auf Zeit gespielt werden.</li>
        <li>Es kann eine Übungsdauer eingestellt werden (10 oder 20 Minuten)</li>
        <li>Es kann eine Aufgabenanzahl eingestellt werden (50 oder 100)</li>
        <li>Es können unterschiedliche Operation ausgewählt werden.</li>
        <li>Die mathematischen Operationen können durchmischt auftreten.</li>
    </ul>
    <i>
        **Diese App speichert keine Daten, Verfolgt nicht, enthält keine Werbung und Analysiert kein Nutzerverhalten
    </i>
    <br>

    <div class="row" style="margin-top: 20px;">
        <div class="col-md-4 d-flex justify-content-center">
            <img src="../../assets/Projekte/calculon/calculon1.png" class="img-fluid" style="max-height: 450px;" alt="calculon1">
        </div>
        <div class="col-md-4 d-flex justify-content-center">
            <img src="../../assets/Projekte/calculon/calculon2.png" class="img-fluid" style="max-height: 450px;" alt="calculon2">
        </div>
        <div class="col-md-4 d-flex justify-content-center">
            <img src="../../assets/Projekte/calculon/calculon3.png" class="img-fluid" style="max-height: 450px;" alt="calculon3">
        </div>
    </div>
</div>
</template>
